<template>
  <div v-if="hasContent" v-html="html" @click="intercept" class="prismic"/>
</template>

<script>
  import PrismicDOM from 'prismic-dom'
  import linkResolver from '../plugins/link-resolver'

  export default {
    name: 'RichText',
    props: {
      rt: [Array, String]
    },
    computed: {
      html () {
        if (!this.rt) return
        if (typeof this.rt === 'string') return this.rt

        const html = PrismicDOM.RichText.asHtml(this.rt, linkResolver) // or add the serializer
        return html?.replace('<p></p>', '')
      },
      hasContent () {
        return this.html?.length
      }
    },
    methods: {
      intercept (event) {
        const { target } = event

        if (target.href && target.host === window.location.host) {
          event.preventDefault()
          const to = target.pathname

          this.$router.push(to)
        }
      }
    }
  }
</script>
