<template>
  <img ref="el" :data-srcset="srcset" :data-src="small" :data-sizes="size" :width="width" :height="height" :key="img.url" :alt="alt" class="lazyload"/>
</template>

<script>
  import { ref } from 'vue'
  const sizes = [400, 800, 1600, 2400]

  const fallback = require('../assets/texture.jpg')

  export default {
    name: 'AppImage',
    props: {
      img: Object
    },
    setup () {
      const el = ref('el')
      return {
        el,
        fallbackAlt: 'Taymer Park'
      }
    },
    computed: {
      small () {
        return this.img?.url + '&w=400'
      },
      srcset () {
        if (!this.img?.url) return `${fallback} 1200w`

        const set = sizes.map((s) => {
          return `${this.img.url}&w=${s} ${s}w`
        }).join(',')

        return set
      },
      size () {
        const el = this.el
        if (!el || typeof el === 'string') return 'auto'

        const w = el.parentElement?.clientWidth
        if (w === window.innerWidth) return '100w'
        return `${w}px`
      },
      width () {
        return this.img?.dimensions?.width || 1200
      },
      height () {
        return this.img?.dimensions?.height || 751
      },
      alt () {
        return this.img?.alt || this.fallbackAlt
      }
    }
  }
</script>
