<template>
  <div>
    <row-select v-for="row in content.body" :key="row.id" :row="row"/>
  </div>
</template>

<script>
  import store from '@/store'

  export default {
    name: 'Home',
    computed: {
      page () {
        return store.state.homepage
      },
      content () {
        return this.page?.data
      }
    },
    beforeRouteEnter (to, from, next) {
      store.dispatch('getHomepage').then(next)
    },
    mounted () {
      store.commit('SEO', this.content)
    }
  }
</script>
