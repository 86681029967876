<template>
  <div class="mx-4 lg:mx-8 py-12 lg:py-16 border-t border-b border-black">
    <div class="max-w-4xl mx-auto sm:my-8 md:my-12">
      <div v-if="!sent">
        <rich-text :rt="headline" class="font-serif text-2xl mb-4 prismic"/>
        <rich-text :rt="description" class="prismic"/>

        <form @submit.prevent="submit" class="relative">
          <div class="flex flex-wrap mt-4 sm:mt-8">
            <div class="w-full sm:w-2/5">
              <input v-model="form.Name" type="text" name="Name" placeholder="Name" class="input">
            </div>
            <div class="w-full sm:w-2/5 sm:px-4 my-8 sm:my-0">
              <input v-model="form.Email" type="email" name="Email" placeholder="Email" class="input">
            </div>
            <div class="no-show">
              <input v-model="form.Add" type="text" name="Add" placeholder="Comments" class="input">
            </div>
            <div class="w-48 sm:w-1/5 pr-4 sm:pr-0 sm:self-center mb-6 md:mb-0">
              <input type="submit" value="Subscribe" class="btn w-full sm:w-auto">
            </div>
            <div class="w-full mt-2 sm:mt-4 sm:self-center">
              <label class="checkbox leading-tight mb-2">
                <input v-model="form.Terms" type="checkbox" class="sr-only">
                <span class="ctrl block w-4 h-4 rounded-sm border border-black transition-colors duration-300">
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false"><path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                </span>
                <rich-text :rt="checkbox" class="text-sm inline inline-p under-a ml-2"/>
              </label>
              <rich-text :rt="terms" class="text-xs prismic text-gray-700"/>
            </div>
          </div>
          <div v-if="errMsg" class="absolute text-sm text-red-600 font-medium mt-2 text-center">{{ errMsg }}</div>
        </form>
      </div>

      <div v-else>
        <rich-text :rt="sentTitle" class="font-serif text-2xl mb-4"/>
        <rich-text :rt="sentBody"/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SignupForm',
    data () {
      return {
        form: {
          Name: null,
          Email: null,
          Add: null,
          Terms: false
        },
        errMsg: null,
        sent: false
      }
    },
    computed: {
      session () {
        return this.$store.state.session
      },
      version () {
        return this.$store.state.settings?.data.consultation_id
      },
      page () {
        return this.$store.state.settings?.data
      },
      headline () {
        return this.page?.newsletter_headline
      },
      description () {
        return this.page?.newsletter_description
      },
      checkbox () {
        return this.page?.newsletter_checkbox
      },
      terms () {
        return this.page?.newsletter_terms
      },
      sentTitle () {
        return this.page?.newsletter_sent_headline
      },
      sentBody () {
        return this.page?.newsletter_sent_body
      }
    },
    methods: {
      async submit () {
        const { Name, Email, Add, Terms } = this.form
        if (!Email) {
          this.errMsg = 'Please provide a valid email address'
          return
        }
        if (!Terms) {
          this.errMsg = 'You must accept the terms of service'
          return
        }

        const elapsed = new Date().getTime() - this.session.getTime()
        const duration = new Date(elapsed).toISOString().substr(11, 8)

        const URL = window.location.href
        const Version = this.version

        try {
          await fetch(process.env.VUE_APP_SIGNUP_URL, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              Name, Email, Add, Terms, URL, Version, duration
            })
          }).then((res) => {
            if (!res.ok) throw res
          })

          this.sent = true
          this.errMsg = null
        } catch (err) {
          if ('message' in err) {
            this.errMsg = 'Something went wrong. Please try again later'
          } else {
            if (err.statusText && err.statusText.length) {
              this.errMsg = `${err.status} ${err.statusText}`
            } else {
              this.errMsg = await err.text()
            }
          }
          this.sent = null
        }
      }
    },
    watch: {
      form: {
        deep: true,
        handler () {
          this.errMsg = null
        }
      }
    }
  }
</script>
