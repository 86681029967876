<template>
  <header class="px-4 lg:px-8 md:sticky top-0 bg-white z-20">
    <div class="border-b border-black py-2 md:py-4 flex justify-between items-center">
      <div>
        <router-link to="/" class="leading-8">
          <h1 v-if="pageTitle" :title="pageTitle" class="uppercase">
            <svg width="96" height="13" viewBox="0 0 96 13" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path fill="#FFF" d="M-15-23h375v60H-15z"/><path d="M4.177 12V2.942h2.42V1.445H.157v1.497h2.427V12h1.593zm4.347 0L9.7 9.437h4.519L15.326 12h1.743L12.031.782 6.81 12h1.715zm5.045-4.06h-3.206l1.64-3.59 1.566 3.59zM22.114 12V7.44l3.46-5.995H23.74l-2.42 4.218-2.413-4.218h-1.832l3.445 5.995V12h1.593zm10.124.485l3.124-6.856L36.381 12h1.634L36.019.652l-3.753 8.203L28.643.652 26.42 12h1.647l1.135-6.378 3.035 6.863zM46.074 12v-1.497h-4.231V6.976h4.108V5.479h-4.108V2.942h4.231V1.445H40.25V12h5.824zm3.685 0V7.68h.287L53.06 12h1.948L51.74 7.502c.702-.15 1.267-.495 1.696-1.032.428-.538.642-1.18.642-1.928 0-.957-.342-1.727-1.025-2.31-.62-.525-1.627-.787-3.022-.787h-1.866V12h1.593zm.506-5.708h-.506v-3.35h.567c1.468 0 2.201.54 2.201 1.62 0 1.153-.754 1.73-2.262 1.73zM62.508 12V7.714h.738c1.098 0 1.971-.283 2.618-.848.647-.565.971-1.33.971-2.297 0-.902-.303-1.65-.91-2.242a2.686 2.686 0 00-1.202-.697c-.452-.123-1.12-.185-2.003-.185h-1.805V12h1.593zm.595-5.763h-.595V2.915h.526c1.509 0 2.263.545 2.263 1.634 0 1.125-.732 1.688-2.194 1.688zM68.98 12l1.176-2.563h4.519L75.783 12h1.743L72.488.782 67.266 12h1.715zm5.045-4.06H70.82l1.64-3.59 1.566 3.59zM80.438 12V7.68h.288L83.74 12h1.948l-3.267-4.498c.702-.15 1.267-.495 1.695-1.032.429-.538.643-1.18.643-1.928 0-.957-.342-1.727-1.026-2.31-.62-.525-1.627-.787-3.021-.787h-1.866V12h1.592zm.506-5.708h-.506v-3.35h.568c1.467 0 2.201.54 2.201 1.62 0 1.153-.754 1.73-2.263 1.73zM88.867 12V7.632l.328-.328L92.996 12h2.133L90.33 6.224l4.772-4.779h-2.126l-4.109 4.211v-4.21h-1.593V12h1.593z" fill="#000" fill-rule="nonzero"/></g></svg>
          </h1>
          <span v-else class="text-sm">{{ load }}</span>
        </router-link>
      </div>
      <div>
        <router-link v-if="showTitle" :to="consultationLink" :class="btnClass" class="btn">
          <span>{{ consultationTitle }}</span>
        </router-link>
        <span v-else class="leading-8">{{ consultationTitle }}</span>
      </div>
    </div>
  </header>
</template>

<script>
  import LinkResolver from '@/plugins/link-resolver'

  export default {
    name: 'AppHeader',
    data () {
      return {
        load: 'Loading...'
      }
    },
    computed: {
      btnClass () {
        const onPage = this.$route.name === 'consultations'
        return onPage ? 'focus' : ''
      },
      settings () {
        return this.$store.state.settings
      },
      pageTitle () {
        return this.settings?.data.title
      },
      consultationLink () {
        if (!this.settings) return ''
        return LinkResolver(this.settings.data.consultation_link)
      },
      consultationTitle () {
        return this.settings?.data.consultation_title
      },
      showTitle () {
        const ch = this.$route.name === 'chapter'
        return !!this.consultationTitle && !ch
      }
    }
  }
</script>
