<template>
  <transition name="fade">
    <div v-if="open" @click="open = false" class="fixed inset-0 bg-darken z-20"></div>
  </transition>

  <transition @enter="showEl" @leave="hideEl">
    <div v-if="open" @keyup.esc="open = false" id="feedback" class="fixed left-0 bottom-0 w-full bg-white z-20 max-h-screen overflow-auto overscroll-none">
      <div class="pb-12 md:pb-32">
        <header class="px-4 lg:px-8 sticky top-0 bg-white z-20">
          <div class="border-b border-black py-2 md:py-3 relative">
            <div >
              <h2 class="font-serif text-xl md:text-2xl italic mr-3 text-center truncate">Consultation feedback</h2>
            </div>
            <div class="absolute top-0 right-0 mt-2 md:mt-4">
              <button @click="open = false" class="hover:text-white hover:fill-black focus:outline-none w-6 lg:w-auto overflow-hidden">
                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="w-full"><g stroke="currentColor" fill="none" fill-rule="evenodd"><circle cx="15" cy="15" r="15" transform="translate(1 1)"/><path d="M22.087 10.492L10.507 22.07M10.508 10.492L22.086 22.07" stroke="currentColor"/></g></svg>
              </button>
            </div>
          </div>
        </header>

        <div class="max-w-app mx-auto md:my-4 lg:my-8 px-4 pb-12 lg:p-8 relative">
          <form v-if="!sent" @submit.prevent="submit">
            <div class="md:flex items-stretch md:-mx-2 lg:-mx-8 my-10 md:my-12 lg:my-16">
              <div v-if="img" class="order-1 md:w-1/2 md:px-2 lg:px-8 mb-8 md:mb-0">
                <app-image :img="img" size="400px"/>
              </div>

              <div class="order-0 md:w-1/2 lg:px-8 md:flex flex-col justify-between md:px-2">
                <div v-if="sent" class="text-2xl font-serif">Feedback sent</div>
                <rich-text v-else :rt="headline" class="text-center md:text-left prismic text-2xl lg:text-4xl font-serif"/>

                <fieldset class="lg:flex lg:-mx-4 mt-8">
                  <div class="mt-4 lg:mt-8 lg:w-1/2 lg:px-4">
                    <input v-model="form.name" id="form-name" name="Name" type="text" placeholder="Name" class="input">
                  </div>
                  <div class="mt-4 lg:mt-8 lg:w-1/2 lg:px-4">
                    <input v-model="form.email" name="Email" type="email" placeholder="Email (required)" class="input" required>
                  </div>
                  <div class="mt-4 lg:mt-8">
                    <input v-model="form.add" type="text" name="Add" hidden>
                  </div>
                </fieldset>
              </div>
            </div>

            <section id="questions">
              <dynamic-question v-for="(q, i) in questions" :data="q" :index="i" :key="i" @output="output" class="my-16 md:my-24"/>
            </section>

            <div class="mt-4 lg:mt-8">
              <label class="checkbox">
                <input v-model="form.terms" type="checkbox" required class="sr-only">
                <span class="ctrl block w-4 h-4 rounded-sm border border-black transition-colors duration-300">
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false"><path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                </span>
                <rich-text :rt="terms" class="text-sm inline inline-p under-a ml-2"/>
              </label>
              <label class="checkbox">
                <input v-model="form.newsletter" type="checkbox" class="sr-only">
                <span class="ctrl block w-4 h-4 rounded-sm border border-black transition-colors duration-300">
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false"><path fill='none' stroke='currentColor' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                </span>
                <rich-text :rt="newsletterMsg" class="text-sm inline inline-p under-a ml-2"/>
              </label>
            </div>

            <div class="mt-4 lg:mt-8">
              <input type="submit" value="Submit" class="btn">
            </div>
          </form>

          <div v-if="errMsg" class="text-red-600 font-medium mt-4 lg:mt-8">{{ errMsg }}</div>

          <div v-if="sent" class="my-4 lg:mt-8">
            <rich-text :rt="msg" class="text-green-600 text-center md:text-lg"/>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import anime from 'animejs'
  import DynamicQuestion from '@/components/DynamicQuestion'

  export default {
    name: 'FeedbackForm',
    data () {
      return {
        form: {
          name: null,
          email: null,
          terms: false,
          newsletter: true,
          add: null
        },

        // answers: [],

        errMsg: null,
        sent: null
      }
    },
    computed: {
      open: {
        get () {
          return this.$store.state.showFeedbackForm
        },
        set (val) {
          if (val) {
            this.$router.push({ hash: '#feedback' })
          } else {
            this.$router.push(this.$route.path)
          }
        }
      },

      answers: {
        get () {
          return this.$store.state.answers
        },
        set (list) {
          this.$store.commit('NEW_ANSWERS', list)
        }
      },
      session () {
        return this.$store.state.session
      },
      page () {
        return this.$store.state.settings?.data
      },
      version () {
        return this.page?.consultation_link?.uid
      },
      headline () {
        return this.page?.headline
      },
      terms () {
        return this.page?.terms
      },
      questions () {
        return this.page?.body || []
      },
      img () {
        return this.page?.image
      },
      msg () {
        return this.page?.msg
      },
      oneAnswer () {
        return this.answers.some((a) => !!a.answer)
      },
      newsletterMsg () {
        return this.page?.newsletter
      }
    },
    methods: {
      async submit () {
        const { name, email, add, terms, newsletter } = this.form
        const answers = this.answers

        if (!email) {
          this.errMsg = 'Email address is required'
          return
        }

        if (!this.oneAnswer) {
          this.errMsg = 'Please answer one or more questions'
          return
        }

        const elapsed = new Date().getTime() - this.session.getTime()
        const duration = new Date(elapsed).toISOString().substr(11, 8)

        const URL = window.location.href
        const version = this.version
        const activity = this.$store.state.activity

        try {
          await fetch(process.env.VUE_APP_FEEDBACK_URL, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              name,
              email,
              add,
              terms,
              newsletter,
              URL,
              version,
              answers,
              duration,
              activity
            })
          }).then((res) => {
            if (!res.ok) throw res
          })

          this.sent = this.msg || 'Feedback sent, thank you'
          this.errMsg = null
        } catch (err) {
          if ('message' in err) {
            this.errMsg = 'Something went wrong. Please try again later'
          } else {
            if (err.statusText && err.statusText.length) {
              this.errMsg = `${err.status} ${err.statusText}`
            } else {
              this.errMsg = await err.text()
            }
          }
          this.sent = null
        }
      },
      output (answer) {
        // update the answers so they can be submitted
        // this.answers.splice(i, 1, data)
        this.answers = answer
      },
      showEl (el, done) {
        const complete = () => {
          // el.style.height = 'auto'
          // autofocus on phones is not nice
          const touchOnly = window.matchMedia && window.matchMedia('(hover: none)')
          if (touchOnly.matches) return

          this.$nextTick(() => {
            const first = document.getElementById('form-name')
            if (first) {
              first.focus()
            }
          })
          done()
        }
        const t = anime.timeline({ complete })
        const r = el.getBoundingClientRect()
        const h = r.height
        el.style.height = 0
        t.add({
          targets: el,
          height: h,
          duration: 400,
          easing: 'easeInOutCubic'
        })
      },
      hideEl (el, done) {
        const t = anime.timeline({ complete: done })
        const h = el.getBoundingClientRect().height
        el.style.height = h + 'px'
        t.add({
          targets: el,
          height: 0,
          duration: 300,
          easing: 'easeInOutCubic'
        })
      }
    },
    watch: {
      page (newVal) {
        if (!newVal) return

        const { autoshow } = this.page
        if (autoshow) {
          setTimeout(() => {
            // this.open = true
          }, autoshow * 1000)
        }
      }
    },
    components: { DynamicQuestion }
  }
</script>
