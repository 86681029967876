import 'lazysizes'

import { createApp } from 'vue'
import App from '@/App.vue'

import router from '@/router'
import store from '@/store'

import vueGtag, { trackRouter } from 'vue-gtag-next'

import RichText from './components/RichText.vue'
import AppImage from '@/components/AppImage'
import RowSelect from '@/components/RowSelect'
import AnchorSwitch from '@/components/AnchorSwitch'

const app = createApp(App)

// plugins
app.use(store).use(router).use(vueGtag, {
  property: { id: process.env.VUE_APP_GTAG },
  isEnabled: false
})
trackRouter(router)

app.component('rich-text', RichText)
app.component('app-image', AppImage)
app.component('row-select', RowSelect)
app.component('anchor-switch', AnchorSwitch)

app.mount('#app')
