<template>
  <div v-if="settings">
    <signup-form/>

    <div class="px-4 lg:px-8">
      <footer class="py-6">
        <div class="sm:flex flex-wrap text-xs">
          <div>
            <rich-text :rt="address" class="prismic break-words"/>
          </div>
          <div class="flex-auto my-6 sm:my-0 sm:mx-4 lg:mx-8">
            <rich-text :rt="contact" class="prismic break-words"/>
          </div>
          <div>
            <ul>
              <li v-for="(item, i) in menu" :key="i">
                <anchor-switch :url="item.link" class="hover:border-b border-black">{{ item.text }}</anchor-switch>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
  import SignupForm from '@/components/SignupForm'

  export default {
    name: 'AppFooter',
    computed: {
      settings () {
        return this.$store.state.settings?.data
      },
      address () {
        return this.settings ? this.settings.footer_address : null
      },
      contact () {
        return this.settings ? this.settings.footer_contact : null
      },
      menu () {
        return this.settings?.footer_menu || []
      }
    },
    components: { SignupForm }
  }
</script>
