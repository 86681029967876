<template>
  <div>
    <app-image :img="img" size="100w"/>
  </div>
</template>

<script>
  export default {
    name: 'FullbleedImage',
    props: {
      row: Object
    },
    computed: {
      img () {
        return this.row.primary?.image
      }
    }
  }
</script>
