<template>
  <div class="px-4 lg:px-8 md:my-12">
    <div class="py-2 md:py-4">
      <div v-if="!reverse" class="my-20 md:my-24 max-w-4xl mx-auto">
        <app-image :img="img"/>
      </div>
      <rich-text :rt="html" class="my-4 md:my-8 text-2xl md:text-3xl lg:text-4xl font-serif text-center max-w-5xl mx-auto"/>
      <div class="flex justify-center items-center mb-12">
        <div v-for="(link, i) in links" :key="`link-${i}`" class="text-center">
          <anchor-switch :url="link.link" class="btn">{{ link.text }}</anchor-switch>
        </div>
      </div>
      <div v-if="reverse" class="my-20 md:my-24 max-w-4xl mx-auto">
        <app-image :img="img"/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeadlineAndImage',
    props: {
      row: Object
    },
    computed: {
      img () {
        return this.row.primary?.image
      },
      html () {
        return this.row.primary?.headline
      },
      links () {
        return this.row.items
      },
      reverse () {
        return this.row.slice_label === 'text_image'
      }
    }
  }
</script>
