<template>
  <div class="">
    <app-header/>
    <contents-menu/>
    <main class="main">
      <router-view/>
    </main>
    <app-footer/>
    <feedback-form/>
  </div>

  <transition name="fade">
    <div v-if="newLink" class="sticky bottom-0 px-4 pb-4 md:px-8 md:pb-8 z-10">
      <div class="rounded-lg bg-black text-white py-2 px-3 lg:p-4 text-sm lg:text-base max-w-xl mx-auto text-center shadow-2xl">
        <p>This consultation is out of date, please view the latest draft <router-link :to="newLink" class="underline">here</router-link></p>
      </div>
    </div>
  </transition>

  <transition name="fade">
    <div v-if="showCookies && cookieNotice.length" class="sticky bottom-0 px-4 pb-4 md:px-8 md:pb-8 z-40 flex">
      <div class="rounded-lg bg-gray-100 text-black py-2 px-3 lg:py-4 lg:px-6 text-sm lg:text-base mx-auto">
        <transition name="fade">
          <div v-if="managing" class="mb-4 max-w-xl">
            <rich-text :rt="cookieDescription"/>
          </div>
        </transition>
        <div class="sm:flex items-center">
          <rich-text :rt="cookieNotice" class="mb-3 sm:pr-6 sm:mb-0"/>
          <div class="flex -mx-3">
            <div class="px-2">
              <button @click="manageOrDecline" class="btn">{{ managing? 'Close' : 'Manage' }}</button>
            </div>
            <div class="px-2">
              <button @click="okCookies" class="btn focus">Continue</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>

  <teleport to="head">
    <title>{{ pageTitle }}</title>
    <meta name="description" :content="pageDescription">
    <meta property="og:title" :content="pageTitle"/>
    <meta property="og:description" :content="pageDescription"/>
    <meta property="og:url" :content="url"/>
    <meta property="og:type" content="website"/>

    <template v-if="pageThumb">
      <meta property="og:image:url" :content="img.url"/>
      <meta property="og:image:secure_url" :content="img.secure_url"/>
      <meta property="og:image:width" :content="img.width"/>
      <meta property="og:image:height" :content="img.height"/>
      <meta property="og:image:alt" :content="img.alt"/>
    </template>
  </teleport>
</template>

<script>
  import AppHeader from '@/components/AppHeader'
  import ContentsMenu from '@/components/ContentsMenu'
  import AppFooter from '@/components/AppFooter'
  import FeedbackForm from '@/components/FeedbackForm'
  import Cookies from 'js-cookie'

  import { useState } from 'vue-gtag-next'

  export default {
    name: 'App',
    data () {
      return {
        showCookies: false,
        managing: false
      }
    },
    setup () {
      const { isEnabled } = useState()
      const enable = () => {
        isEnabled.value = true
      }

      return {
        enable
      }
    },
    computed: {
      pageTitle () {
        return this.$store.getters.siteTitle
      },
      pageDescription () {
        return this.$store.state.pageDescription
      },
      pageThumb () {
        return this.$store.state.pageThumb
      },
      img () {
        if (!this.pageThumb) return
        const u = this.pageThumb.url + '&w=1200&h=630&fit=crop&crop=entopy'

        return {
          url: u.replace('https://', 'http://'),
          secure_url: u,
          width: 1200,
          height: 630,
          alt: this.pageThumb.alt
        }
      },
      url () {
        return window.location.href
      },
      expired () {
        return this.$store.getters.expired
      },
      newLink () {
        if (!this.expired) return
        if (!this.$route.path.includes('/consultations')) return

        return `/consultations/${this.expired}`
      },
      settings () {
        return this.$store.state.settings?.data
      },
      cookieNotice () {
        if (!this.settings) return []
        return this.settings.cookies
      },
      cookieDescription () {
        if (!this.settings) return []
        return this.settings.cookies_description
      }
    },
    methods: {
      okCookies () {
        this.showCookies = false
        this.enable()
        Cookies.set('com.taymer.cookies', new Date().getTime())
      },
      manageOrDecline () {
        if (!this.managing) {
          this.managing = true
        } else {
          this.showCookies = false
          this.managing = false
        }
      }
    },
    mounted () {
      this.$store.dispatch('getSettings')
      this.$store.commit('SESSION_START', new Date(), { expires: 365 })

      const agreed = Cookies.get('com.taymer.cookies')
      if (!agreed) {
        this.showCookies = true
      } else {
        this.enable()
      }
    },
    components: { AppHeader, ContentsMenu, AppFooter, FeedbackForm }
  }
</script>

<style>
  @import './style/global.css';
</style>
