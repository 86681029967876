<template>
  <fieldset>
    <div class="text-xl md:text-2xl font-serif mb-4 md:mb-8 w-full flex">
      <div>{{ index + 1 }}.</div>
      <legend class="ml-4">{{ question }}</legend>
    </div>
    <div class="sm:flex flex-wrap -mx-4">
      <div v-for="(o, i) in options" :key="i" class="px-4 mb-px-6 mb-4">
        <label class="radio">
          <input v-model="form.answer" :value="o.answer" :name="`Q${index + 1} Answer`" type="radio" class="sr-only">
          <span class="ctrl block w-4 h-4 rounded-full border border-black transition-colors duration-300"></span>
          <span class="ml-4">{{ o.answer }}</span>
        </label>
      </div>
    </div>
    <div v-if="showCommentField" class="mt-6">
      <div v-comment ref="msg" @paste="pasteEvent" :class="{ 'empty': noMsg }" :name="`Q${1} Comment`" contenteditable class="textarea min-h-comment"></div>
    </div>
  </fieldset>
</template>

<script>
  export default {
    name: 'DynamicQuestion',
    props: {
      data: {
        type: Object,
        required: true
      },
      index: Number
    },
    data () {
      return {
        form: {
          answer: null,
          comment: null
        }
      }
    },
    computed: {
      question () {
        return this.data?.primary.q || ''
      },
      options () {
        return this.data?.items || []
      },
      showCommentField () {
        return this.data?.primary.comments
      },
      noMsg () {
        return !this.form.comment || !this.form.comment.length
      },
      answer () {
        return this.$store.state.answers[this.index]
      }
    },
    methods: {
      pasteEvent (e) {
        setTimeout(() => {
          const txtOnly = this.$refs.msg.innerHTML.replace(/(<([^>]+)>)/ig, '')
          // .replace(/&nbsp;/g, ' ')
          const editable = this.$refs.msg
          editable.innerHTML = txtOnly
          const range = document.createRange()
          const sel = window.getSelection()
          range.setStart(editable.childNodes[0], editable.innerText.length)
          range.collapse(true)
          sel.removeAllRanges()
          sel.addRange(range)
        }, 0)
      }
    },
    created () {
      this.form = { ...this.answer }
    },
    directives: {
      comment: {
        mounted (el, binding) {
          const vm = binding.instance

          const { comment } = vm.form
          if (comment) {
            el.innerText = comment
          }
          el.addEventListener('input', ({ target }) => {
            vm.form.comment = target.innerText
          })
        }
      }
    },
    watch: {
      form: {
        deep: true,
        handler (newVal) {
          const data = {
            question: this.question.slice(0),
            ...newVal
          }
          this.$emit('output', {
            i: this.index,
            data
          })
        }
      }
    }
  }
</script>

<style>
  .empty:before {
    content: 'Your comments';
    @apply text-gray-500;
  }
</style>
