<template>
  <div class="md:px-4 lg:px-8 mt-8 lg:mt-24">
    <div class="py-2 md:py-4 lg:py-8">
      <p v-if="loading" class="text-center font-serif italic">Loading...</p>

      <div v-else class="md:flex flex-wrap items-stretch md:-mx-4 px-2">
        <div class="md:w-1/3 md:flex flex-col justify-between px-2 sm:px-4 mb-4 md:mb-0">
          <rich-text :rt="html" class="text-2xl md:text-3xl lg:text-4xl font-serif prismic mb-4"/>
          <rich-text :rt="description" class="max-w-lg text-sm prismic"/>
        </div>

        <div @click="fix(false)" class="md:w-2/3 px-2 sm:px-4 relative">
          <app-image :img="img"/>

          <div v-for="(spot, i) in imgDots" :key="i" :style="spotPosition(spot)" @click.stop="fix(spot.idx)" class="absolute z-10">
            <div @mouseenter="show(spot.idx)" @mouseleave="hide(spot.idx)" :class="spotClass(spot)" class="bg-white border border-black rounded-full w-6 h-6 md:w-8 md:h-8 text-2xs md:text-xs cursor-pointer flex items-center justify-center transition-opacity duration-200">
              <span>{{ spot.idx + 1 }}</span>
              <span v-if="section === spot.idx">{{ spot.sub }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 lg:mt-16">
        <div ref="wrap" class="flex overflow-x-auto md:-mx-4 snap-wrap pb-4">
          <div v-for="(spot, i) in dots" :key="`section-${i}`" :class="{ 'opacity-50': over && section !== i }" class="flex-none mx-4 border-t border-black pt-4 transition-opacity duration-200 snap-start code-width">
            <div @mouseenter="show(i)" @mouseleave="hide(i)">
              <h4>{{ spot.primary.heading }}</h4>
              <div class="flex mt-4 -mx-2">
                <div v-for="(item, g) in spot.items" :key="`section-${i}-${g}`" class="mx-2 w-24 relative">
                  <div :class="{ 'opacity-0': section !== i }" class="absolute left-0 top-0 bg-white border border-black rounded-full w-8 h-8 text-xs flex items-center justify-center transition-opacity duration-200">
                    <span>{{ i + 1 }}{{ 'abcdefghijkl'.charAt(g) }}</span>
                  </div>
                  <div class="w-24 h-24 overflow-hidden border border-black rounded-full">
                    <app-image :img="item.swatch" class="object-cover"/>
                  </div>
                  <div class="text-xs mt-4 inline-block uppercase">{{ item.title }}</div>
                </div>
              </div>
              <rich-text :rt="spot.primary.info" class="text-xs prismic break-word max-w-xs"/>
            </div>
          </div>
          <div class="w-1 flex-none"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import debounce from 'debounce'

  export default {
    name: 'DesignCode',
    props: {
      row: Object
    },
    data () {
      return {
        section: false,
        fixSection: false
      }
    },
    computed: {
      docId () {
        return this.row.primary?.document?.id
      },
      page () {
        return this.$store.state.docs[this.docId]
      },
      content () {
        return this.page?.data
      },
      img () {
        return this.content?.image
      },
      dimensions () {
        return this.img?.dimensions
      },
      html () {
        return this.content?.headline
      },
      description () {
        return this.content?.description
      },
      loading () {
        return this.page === undefined
      },
      dots () {
        return this.content?.body.map(d => d)
      },
      imgDots () {
        if (!this.dots) return []
        return this.dots.reduce((a, c, idx) => {
          const ext = c.items.map((it, g) => {
            const sub = 'abcdefghijkl'.charAt(g)
            return { idx, sub, ...it }
          })
          return a.concat(...ext)
        }, [])
      },
      over () {
        if (this.section >= 0) return this.dots[this.section]
        return false
      },
      touchable () {
        return window.matchMedia && window.matchMedia('(hover: none)').matches
      }
    },
    methods: {
      spotPosition ({ x, y }) {
        const top = (y / (this.dimensions.height || 1)) * 100
        const left = (x / (this.dimensions.width || 1)) * 100
        return {
          top: `${top}%`,
          left: `${left}%`,
          transform: 'translate(-50%, -50%)'
        }
      },
      spotClass (spot) {
        if (!this.over && spot.sub !== 'a') return 'opacity-0 pointer-events-none'
        if (this.over && this.section !== spot.idx) return 'opacity-0 pointer-events-none'
      },
      show (i) {
        this.section = i
      },
      hide (i) {
        if (this.section === i) {
          this.section = false
        }
      },
      fix (i) {
        this.section = i
        if (this.touchable) {
          const t = this.$refs.wrap?.children[i]
          if (t) {
            t.scrollIntoView({
              block: 'nearest',
              inline: 'start',
              behavior: 'smooth'
            })
          }
        }
      },
      listen: debounce(function ({ target }) {
        const kids = target.children
        const max = target.scrollLeft + (target.clientWidth / 2)

        let i = 0
        while (i < kids.length) {
          if (kids[i].offsetLeft < max) {
            this.section = i
          }
          i++
        }
      }, 10)
    },
    created () {
      this.$store.dispatch('getDocument', this.docId)
    },
    mounted () {
      const { wrap } = this.$refs
      wrap.addEventListener('scroll', this.listen)
    }
  }
</script>
