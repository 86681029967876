import Prismic from 'prismic-javascript'
import linkResolver from '@/plugins/link-resolver'

export default {
  async getSettings ({ state, commit }) {
    if (state.settings) return
    const p = await api()
    const q = Prismic.Predicates.at('document.type', 'settings', {
      fetchlinks: 'consultation.uid'
    })
    return p.query(q).then(({ results }) => {
      commit('SET_SETTINGS', results[0])
    })
  },
  async getHomepage ({ state, commit }) {
    if (state.homepage) return
    const p = await api()
    return p.query(
      Prismic.Predicates.at('document.type', 'home')
    ).then(({ results }) => {
      commit('SET_HOMEPAGE', results[0])
    })
  },
  async getConsultation ({ state, getters, commit, dispatch }, version) {
    if (state.consultation) {
      if (!state.chapters) return dispatch('getChapters', getters.chapterIDs)
      return
    }
    const p = await api()
    return p.query(
      Prismic.Predicates.at('my.consultation.uid', version)
    ).then(({ results }) => {
      commit('SET_CONSULTATION', results[0])
      if (!getters.chapterIDs?.length) return
      return dispatch('getChapters', getters.chapterIDs)
    })
  },
  async getChapters ({ state, commit }, ids) {
    // if (state.chapters) return
    const p = await api()
    return p.getByIDs(ids).then(({ results }) => {
      commit('SET_CHAPTERS', results)
    })
  },
  async getChapter ({ state, commit }, slug) {
    if (state.chapters) return
    const p = await api()
    return p.query(
      Prismic.Predicates.at('my.chapter.uid', slug)
    ).then(({ results }) => {
      commit('SET_CHAPTERS', results)
    })
  },
  async getDocument ({ state, commit }, id) {
    if (state.docs[id]) return
    const p = await api()
    return p.getByID(id).then((res) => {
      commit('ADD_DOC', res)
    })
  },
  async getPage ({ state, commit }, slug) {
    if (state.pages[slug]) return
    const p = await api()
    return p.query(
      Prismic.Predicates.at('my.page.uid', slug)
    ).then(({ results }) => {
      commit('SET_PAGE', { slug, data: results[0] })
    })
  },
  async initPreview (_, { token, documentId }) {
    const p = await api()
    return p.getPreviewResolver(token, documentId).resolve(linkResolver, '/')
  }
}

let client = null
let apiLoading = false

// init the Prismic API, but only once
async function api () {
  if (apiLoading) {
    await apiLoading
    apiLoading = false
  }
  if (!client) {
    apiLoading = Prismic.api(process.env.VUE_APP_PRISMIC_ENDPOINT)
    client = await apiLoading
    apiLoading = false
  }
  return client
}
