import { h } from 'vue'
import linkResolver from '@/plugins/link-resolver'
import { RouterLink } from 'vue-router'

export default {
  name: 'AnchorSwitch',
  props: {
    url: [String, Object],
    tag: {
      type: String,
      default: 'a'
    }
  },
  render () {
    let { url } = this
    const classes = this.$attrs.class
    url = extractLink(url)
    if (!url) {
      return h('span', { class: classes }, this.$slots.default())
    }
    if (url.includes('http')) {
      return h('a', {
        href: url,
        class: classes
      }, this.$slots.default())
    }
    return h(RouterLink, {
      tag: this.tag,
      to: url,
      class: classes
    }, this.$slots.default)
  }
}

export function extractLink (val) {
  if (typeof val === 'string' || !val) return val
  switch (val.link_type) {
  case 'Document':
    return linkResolver(val)
  case 'Web':
  case 'Media':
    return makeRelative(val.url)
  default:
    return false
  }
}

function makeRelative (url = '') {
  return url.replace(window.location.origin, '')
}
