<template>
  <div>
    <row-select v-for="row in content.body" :key="row.id" :row="row"/>

    <div v-if="previous && !next" class="mx-4 lg:mx-8 py-4 lg:py-8 flex justify-center">
      <router-link :to="prevLink" class="flex items-center justify-between">
        <div class="flex-auto font-medium text-2xl font-serif">&larr;</div>
        <div class="mx-4 lg:mx-12 text-right">
          <p class="text-xs">PREVIOUS CHAPTER</p>
          <h4 class="font-serif text-lg lg:text-2xl">{{ previous.data.title }}</h4>
        </div>
        <div class="w-40 h-24 relative">
          <app-image :img="previous.data.thumbnail" class="absolute object-cover w-full h-full"/>
        </div>
      </router-link>
    </div>

    <template v-if="next && previous">
      <div class="mx-4 lg:mx-8 py-2 sm:py-4 lg:py-8 overflow-hidden">
        <div class="flex justify-center items-stretch -mx-4">
          <div v-if="previous" :class="{ 'border-r': previous && next }" class="w-1/2 p-4 lg:py-4 flex-none border-black">
            <router-link :to="prevLink" class="flex flex-col md:flex-row items-center justify-between h-full">
              <div class="hidden md:block flex-auto font-medium text-2xl font-serif text-right">&larr;</div>
              <div class="text-right md:mx-4 lg:mx-12 flex-auto md:flex-initial ml-auto">
                <p class="text-xs">PREVIOUS CHAPTER</p>
                <h4 class="font-serif text-lg lg:text-2xl md:whitespace-normal leading-tight flex-auto">{{ previous.data.title }}</h4>
              </div>
              <div class="md:hidden font-medium text-2xl font-serif ml-auto">&larr;</div>
              <div class="pb-aspect-9/16 md:pb-0 md:w-48 md:h-24 relative w-full flex-none">
                <app-image :img="previous.data.thumbnail" class="absolute object-cover w-full h-full"/>
              </div>
            </router-link>
          </div>

          <div v-if="next" class="w-1/2 p-4 lg:py-4 flex-none">
            <router-link :to="nextLink" class="flex flex-col md:flex-row items-center justify-between h-full">
              <div class="order-2 hidden md:block flex-auto font-medium text-2xl font-serif">&rarr;</div>
              <div class="md:order-1 md:mx-4 lg:mx-12 flex-auto md:flex-initial mr-auto">
                <p class="text-xs">NEXT CHAPTER</p>
                <h4 class="font-serif text-lg lg:text-2xl md:whitespace-normal leading-tight flex-auto">{{ next.data.title }}</h4>
              </div>
              <div class="md:hidden font-medium text-2xl font-serif mr-auto">&rarr;</div>
              <div class="md:order-0 pb-aspect-9/16 md:pb-0 md:w-48 md:h-24 relative w-full flex-none">
                <app-image :img="next.data.thumbnail" class="absolute object-cover w-full h-full"/>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </template>

    <div v-if="next && !previous" class="mx-4 lg:mx-8 py-4 lg:py-8 flex justify-center">
      <router-link :to="nextLink" class="flex items-center justify-between">
        <div class="w-40 h-24 relative flex-none">
          <app-image :img="next.data.thumbnail" class="absolute object-cover w-full h-full"/>
        </div>
        <div class="mx-4 lg:mx-12">
          <p class="text-xs">NEXT CHAPTER</p>
          <h4 class="font-serif text-lg lg:text-2xl">{{ next.data.title }}</h4>
        </div>
        <div class="flex-auto font-medium text-2xl font-serif">&rarr;</div>
      </router-link>
    </div>

    <!-- <div :class="{ sticky: !expired }" class="mx-4 lg:mx-8 border-b border-black bottom-47 lg:bottom-55"></div> -->
    <div v-if="feedbackActive" :class="{ sticky: !expired }" class="px-4 lg:px-8 bg-white bottom-0 z-10">
      <div class="py-2 lg:py-4 border-t border-black">
        <button @click="showForm" class="btn mx-auto">Leave your feedback</button>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'

  export default {
    name: 'Chapter',
    computed: {
      uid () {
        return this.$route.params.chapter
      },
      version () {
        return this.$route.params.version
      },
      chapters () {
        return store.state.chapters
      },
      chapterPaths () {
        return store.getters.chapterPaths
      },
      pageIdx () {
        return this.chapterPaths.findIndex(c => c === this.uid)
      },
      page () {
        return this.chapters[this.pageIdx]
      },
      content () {
        return this.page?.data || []
      },
      next () {
        const plus = this.pageIdx + 1
        if (plus >= store.state.chapters.length) return

        return this.chapters[plus]
      },
      nextLink () {
        if (!this.next || !this.version) return ''
        const slug = this.chapterPaths[this.pageIdx + 1]
        return `/consultations/${this.version}/${slug}`
      },
      previous () {
        if (this.pageIdx === 0) return
        return this.chapters[this.pageIdx - 1]
      },
      prevLink () {
        if (!this.previous || !this.version) return ''
        const slug = this.chapterPaths[this.pageIdx - 1]
        return `/consultations/${this.version}/${slug}`
      },
      expired () {
        return store.getters.expired
      },
      settings () {
        return store.state.settings
      },
      feedbackActive () {
        return this.settings?.data.feedback_active
      }
    },
    methods: {
      showForm () {
        // store.commit('FEEDBACK_FORM_VISIBLE', true)
        this.$router.push({ hash: '#feedback' })
      },
      seo () {
        store.commit('SEO', this.content)
      }
    },
    watch: {
      uid (newVal) {
        if (newVal) {
          this.seo()
        }
      }
    },
    mounted () {
      this.seo()
      if (!this.version) {
        const { version } = this.content
        if (version?.uid) {
          store.dispatch('getConsultation', version.uid).then(() => {
            this.$router.replace(`/consultations/${version.uid}/${this.uid}`)
          })
        }
      }
    },
    beforeRouteEnter (to, from, next) {
      const { version, chapter } = to.params
      if (!version) {
        store.dispatch('getChapter', chapter).then(next)
      } else {
        store.dispatch('getConsultation', version).then(next)
      }
    }
  }
</script>
