<template>
  <div class="mb-32">
    <div class="px-4 lg:px-8 text-center">
      <h1 class="text-xl md:text-3xl font-medium pt-16">404</h1>
      <div class="mt-4">
        <p>This page does not exist</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NotFound',
    mounted () {
      this.$store.commit('SEO', { title: '404', description: 'Page not found' })
    }
  }
</script>
