<template>
  <div>
    <div :class="{ 'hidden md:block': hasMob }" class="md:px-4 lg:px-8 my-12 lg:my-16 relative">
      <div class="relative overflow-hidden touch:overflow-x-auto z-0">
        <div ref="wrap" :style="zoom" class="transition-transform duration-400 ease-out">
          <app-image :img="img" size="100w" class="img-map"/>
        </div>

        <div v-for="(spot, i) in row.items" :key="i" :style="spotPosition(spot)" class="absolute z-10">
          <div @mouseenter="show(i)" @mouseleave="hide(i)" :class="spotClass(i)" class="border border-black rounded-full w-6 h-6 md:w-8 md:h-8 text-2xs md:text-xs cursor-pointer flex items-center justify-center transition-opacity duration-200">
            <span>{{ i + 1 }}</span>
          </div>

          <transition name="fade">
            <div v-if="!touchable && vis === i" :style="labelPosition(spot)" class="absolute left-0 top-0 bg-white border border-black rounded-lg pointer-events-none w-56 mt-10 ml-10 px-3 py-2">
              <h4 class="font-serif">{{ spot.headline }}</h4>
              <rich-text :rt="spot.description" class="prismic text-sm first-p-mt-2"/>
            </div>
          </transition>
        </div>

        <transition name="fade">
          <div v-if="over && over.mask?.url" class="absolute inset-0 z-0">
            <div :style="zoom">
              <app-image :img="over.mask" size="100w" class="img-map"/>
            </div>
          </div>
        </transition>
      </div>

      <transition name="fade">
        <div v-if="touchable && over" class="mt-2 absolute left-0 right-0 px-4">
          <div class="border-b border-black bg-white pb-2 shadow-xl">
            <div class="max-w-sm">
              <h4 class="font-serif">{{ over.headline }}</h4>
              <rich-text :rt="over.description" class="prismic text-sm mt-2"/>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="showArrows" class="absolute inset-0 flex flex-col items-center justify-center pointer-events-none">
          <div class="text-4xl text-white font-serif text-shadow font-bold">
            <span class="mr-6 inline-block">&larr;</span>
            <span class="ml-6 inline-block">&rarr;</span>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="hasMob" class="md:hidden">
      <app-image :img="mobileImg" size="100w"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ImageMap',
    props: {
      row: Object
    },
    data () {
      return {
        vis: false,
        lastOrigin: null,
        multi: 1,
        showArrows: false
      }
    },
    computed: {
      img () {
        return this.row.primary?.image
      },
      dimensions () {
        return this.img?.dimensions
      },
      overlays () {
        return this.row.items || []
      },
      over () {
        if (this.vis >= 0) {
          return this.overlays[this.vis]
        } else {
          return false
        }
      },
      zoom () {
        if (this.touchable) return

        const st = { 'transform-origin': this.lastOrigin }
        if (this.over) {
          st.transform = `scale(${this.over.zoom / 100})`
        }
        return st
      },
      mobileImg () {
        return this.row.primary?.mobile_image
      },
      hasMob () {
        // console.log(this.mobileImg)
        return this.mobileImg?.url?.length > 0
      },
      touchable () {
        return window.matchMedia && window.matchMedia('(hover: none)').matches
      }
    },
    methods: {
      spotPosition ({ x, y }) {
        const top = (y / (this.dimensions?.height || 1)) * 100
        const left = (x / (this.dimensions?.width || 1)) * 100 * this.multi
        return {
          top: `${top}%`,
          left: `${left}%`,
          transform: 'translate(-50%, -50%)'
        }
      },
      labelPosition ({ x, y }) {
        const top = (y / (this.dimensions?.height || 1)) * 100
        // const left = (x / (this.dimensions?.width || 1)) * 100 * this.multi
        const styles = {}

        if (top > 85) {
          styles.top = 'auto'
          styles.bottom = '0'
        }

        return styles
      },
      spotClass (i) {
        const cl = []
        if (this.touchable) {
          cl.push(this.vis === i ? 'bg-black text-white' : 'bg-white')
          if (this.showArrows) {
            cl.push('opacity-0')
          }
        } else {
          cl.push('bg-white')
          if (this.over && this.vis !== i) {
            cl.push('opacity-0')
          }
        }
        return cl
      },
      show (i) {
        this.vis = i
        if (this.touchable) return

        const top = (this.over.y / (this.dimensions.height || 1)) * 100
        const left = (this.over.x / (this.dimensions.width || 1)) * 100 * this.multi
        this.lastOrigin = `${left}% ${top}%`
      },
      hide (i) {
        if (this.vis === i) {
          this.vis = false
        }
      }
    },
    mounted () {
      if (!this.touchable) return

      const { wrap } = this.$refs

      const bw = this.dimensions?.width
      const bh = this.dimensions?.height

      const img = wrap.firstElementChild
      const ww = wrap.getBoundingClientRect().width
      const ih = img.getBoundingClientRect().height

      const iw = (ih / bh) * bw

      this.multi = iw / ww

      wrap.firstElementChild.addEventListener('load', () => {
        wrap.parentElement.scrollTo((iw - ww) / 2, 0)
        this.showArrows = true
        setTimeout(() => {
          this.showArrows = false
        }, 3400)
      }, { once: true })
    }
  }
</script>
