export default {
  SET_SETTINGS (state, data) {
    state.settings = data
  },
  SEO (state, data) {
    state.pageTitle = data?.title
    state.pageDescription = data?.seo_description
    state.pageThumb = data?.thumbnail
  },
  SET_HOMEPAGE (state, data) {
    state.homepage = data
  },
  SET_CONSULTATION (state, data) {
    state.consultation = data
  },
  SET_CHAPTERS (state, data) {
    state.chapters = data
  },
  FEEDBACK_FORM_VISIBLE (state, bool) {
    state.showFeedbackForm = bool
  },
  ADD_DOC (state, doc) {
    state.docs[doc.id] = doc
  },
  SET_PAGE (state, { slug, data }) {
    state.pages[slug] = data
  },

  NEW_ANSWERS (state, { i, data }) {
    state.answers.splice(i, 1, data)
  },

  SESSION_START (state, time) {
    state.session = time
  },

  ADD_ACTIVITY (state, path) {
    const ts = new Date().toISOString()
    state.activity += `${ts} ${path}\n`
  }
}
