export default function (doc) {
  const version = doc.data?.version
  switch (doc.type) {
    case 'page':
      return `/page/${doc.uid}`
    case 'consultation':
      return `/consultations/${doc.uid}`
    case 'chapter':
      return version ? `/consultations/${version.uid}/${doc.slug}` : `/chapter/${doc.slug}`
    default:
      return `/${doc.uid ? '/' + doc.uid : ''}`
  }
}
