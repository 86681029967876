<template>
  <div class="px-4 lg:px-8">
    <div class="py-8 my-8 lg:my-16">
      <rich-text :rt="html" class="md:my-12 text-2xl md:text-3xl lg:text-4xl font-serif text-center max-w-5xl mx-auto prismic"/>
      <div v-if="hasDescription" class="mb-12 text-md max-w-xl mx-auto">
        <rich-text :rt="description" class="prismic"/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Headline',
    props: {
      row: Object
    },
    computed: {
      html () {
        return this.row.primary?.headline
      },
      description () {
        const { description } = this.row.primary
        return description
      },
      hasDescription () {
        return this.row.primary && this.description.length
      }
    }
  }
</script>
