<template>
  <div class="sticky top-0 md:inline z-20">
    <transition name="fade">
      <div v-if="uid" class="top-0 md:fixed md:left-1/2 md:transform md:-translate-x-1/2 md:my-3 pt-2 md:py-1 bg-white px-4 md:px-0 md:z-20">
        <label @click="open = true" class="flex items-center justify-center cursor-pointer border-b border-black md:border-none pb-2 md:pb-0">
          <h1 class="font-serif text-xl md:text-2xl italic mr-3 md:max-w-half-screen truncate">{{ page?.data.title }}</h1>
          <button class="hover:text-white hover:fill-black focus:outline-none overflow-hidden w-6 md:w-auto">
            <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="w-full"><g transform="translate(1 1)" stroke="currentColor" fill="none" fill-rule="evenodd"><circle cx="15" cy="15" r="15"/><path d="M20.657 13L15 18.657 9.343 13" stroke="currentColor"/></g></svg>
          </button>
        </label>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="open" @click="open = false" class="fixed inset-0 bg-darken z-20"></div>
    </transition>

    <transition @enter="showEl" @leave="hideEl">
      <div v-if="open" class="fixed top-0 left-0 w-full bg-white z-20 overflow-hidden">
        <div class="p-4 lg:p-8">
          <div class="flex justify-between items-center">
            <div>
              <router-link :to="backLink" class="btn">{{ consultationTitle || 'Overview' }} overview</router-link>
            </div>
            <div class="leading-none pt-px">
              <button @click="open = false" class="hover:text-white hover:fill-black focus:outline-none overflow-hidden w-7 md:w-auto">
                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="w-full"><g stroke="currentColor" fill="none" fill-rule="evenodd"><circle cx="15" cy="15" r="15" transform="translate(1 1)"/><path d="M22.087 10.492L10.507 22.07M10.508 10.492L22.086 22.07" stroke="currentColor"/></g></svg>
              </button>
            </div>
          </div>

          <div class="columns sm:columns-2 my-10 max-w-app">
            <ol class="text-xl md:text-2xl lg:text-4xl font-serif list-decimal list-outside">
              <li v-for="(ch, i) in chapters" :key="ch.id" class="ml-em my-1">
                <router-link :to="chapterLink(i)" class="chapter">{{ ch.data.title }}</router-link>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import anime from 'animejs'

  export default {
    name: 'ContentsMenu',
    data () {
      return {
        open: false
      }
    },
    computed: {
      version () {
        return this.$route.params.version ||
          this.$store.state.consultation?.uid
      },
      uid () {
        return this.$route.params.chapter
      },
      chapters () {
        return this.$store.state.chapters || []
      },
      chapterPaths () {
        return this.$store.getters.chapterPaths
      },
      pageIdx () {
        return this.chapterPaths.findIndex(c => c === this.uid)
      },
      page () {
        return this.chapters[this.pageIdx]
      },
      consultationTitle () {
        return this.$store.state.settings?.data.consultation_title
      },
      backLink () {
        return `/consultations/${this.version}`
      }
    },
    methods: {
      chapterLink (i) {
        return `/consultations/${this.version}/${this.chapterPaths[i]}`
      },
      showEl (el, done) {
        const complete = () => {
          el.style.height = 'auto'
          done()
        }
        const t = anime.timeline({ complete })
        const r = el.getBoundingClientRect()
        const h = r.height
        el.style.height = 0
        t.add({
          targets: el,
          height: h,
          duration: 300,
          easing: 'easeInOutCubic'
        })
      },
      hideEl (el, done) {
        const t = anime.timeline({ complete: done })
        const h = el.getBoundingClientRect().height
        el.style.height = h + 'px'
        t.add({
          targets: el,
          height: 0,
          duration: 220,
          easing: 'easeInOutCubic'
        })
      }
    },
    watch: {
      uid (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.open = false
        }
      }
    },
    mounted () {
      document.addEventListener('keyup', ({ key }) => {
        if (this.open && key === 'Escape') {
          this.open = false
        }
      })
    }
  }
</script>
