<template>
  <component :is="elType" v-bind="$attrs" :row="row" class="content"/>
</template>

<script>
  import Headline from '@/components/rows/Headline'
  import HeadlineAndImage from '@/components/rows/HeadlineAndImage'
  import FullbleedImage from '@/components/rows/FullbleedImage'
  import HeadlineAndImageColumn from '@/components/rows/HeadlineAndImageColumn'
  import HorizontalRule from '@/components/rows/HorizontalRule'
  import ImageMap from '@/components/rows/ImageMap'
  import DesignCode from '@/components/rows/DesignCode'

  const custom = {
    headline_and_image: 'HeadlineAndImage',
    headline: 'Headline',
    image: 'FullbleedImage',
    headline_and_image_column: 'HeadlineAndImageColumn',
    border: 'HorizontalRule',
    image_map: 'ImageMap',
    design_code: 'DesignCode'
  }

  export default {
    abstract: true,
    props: {
      row: Object
    },
    computed: {
      elType () {
        const el = this.row?.slice_type

        return custom[el] || 'div'
      }
    },
    components: {
      Headline,
      HeadlineAndImage,
      FullbleedImage,
      HeadlineAndImageColumn,
      HorizontalRule,
      ImageMap,
      DesignCode
    }
  }
</script>
