<template>
  <hr :class="margins" class="border-black mx-4 lg:mx-8" />
</template>

<script>
  export default {
    name: 'HorizontalRule',
    props: {
      row: Object
    },
    computed: {
      margins () {
        const opts = {
          default: '-mb-6 lg:-mt-4 lg:-mb-24',
          'negative top': '-mt-2 lg:-mt-4',
          'negative bottom': '-mb-2 lg:-mb-4'
        }
        return opts[this.row.primary.margin]
      }
    }
  }
</script>
