export default {
  siteTitle ({ settings, pageTitle }) {
    const prefix = pageTitle ? pageTitle + ' - ' : ''
    const site = settings?.data.title || 'Taymer Park'
    return prefix + site
  },
  chapterIDs ({ consultation }) {
    if (!consultation) return []
    return consultation.data.chapters.map(c => c.chapter.id)
  },
  chapterPaths ({ consultation, chapters }) {
    if (!consultation && !chapters) return []
    if (!consultation && chapters?.length) {
      return chapters.map((c) => {
        const p = c.uid || c.slug || c.slugs[0]
        return p.toLowerCase().replace(/\s/g, '-')
      })
    }
    return consultation.data.chapters.map((c) => {
      const p = c.chapter.uid || c.chapter.slug || c.chapter.id
      return p.toLowerCase().replace(/\s/g, '-')
    })
  },
  expired ({ settings, consultation }) {
    if (!settings || !consultation) return false
    if (settings.data.consultation_link?.type !== 'consultation') return false

    if (settings.data.consultation_link?.uid !== consultation.uid) {
      return settings.data.consultation_link?.uid
    } else {
      return false
    }
  }
}
