import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home'
import Chapter from '@/views/Chapter'
import NotFound from '@/views/NotFound'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/consultations/:version',
    name: 'consultations',
    component: () => import(/* webpackChunkName: "consult" */ '@/views/Consultations')
  },
  {
    path: '/consultations/:version/:chapter',
    name: 'chapter',
    component: Chapter
  },
  {
    path: '/chapter/:chapter',
    name: 'direct-chapter',
    component: Chapter
  },
  {
    path: '/page/:slug',
    name: 'page',
    component: () => import(/* webpackChunkName: "page" */ '@/views/Page')
  },
  {
    path: '/preview',
    name: 'preview',
    component: () => import(/* webpackChunkName: "preview" */ '@/views/Preview')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
    noPrefix: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash || from.hash) return

    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  }
})

router.afterEach((to) => {
  store.commit('FEEDBACK_FORM_VISIBLE', to.hash === '#feedback')
  store.commit('ADD_ACTIVITY', to.fullPath)

  // prerender good to go
  window.prerenderReady = true
})

export default router
