<template>
  <div class="px-2 lg:px-8 mt-8 lg:mt-24">
    <div class="py-4 lg:py-8">
      <div class="md:flex flex-wrap items-stretch md:-mx-4">
        <div :class="{ 'order-1': reverse }" class="md:w-1/2 mx-auto md:flex flex-col px-2 sm:px-4 mb-4 md:mb-0">
          <rich-text :rt="html" class="text-2xl md:text-3xl lg:text-4xl font-serif prismic mb-4 md:mb-6 lg:mb-8 leading-snug"/>
          <rich-text :rt="description" class="max-w-lg text-base"/>
        </div>
        <div :class="{ 'order-0': reverse }" class="mt-6 md:my-0 md:w-1/2 mx-auto px-2 sm:px-4">
          <app-image :img="img"/>
        </div>

        <div v-if="content && content[0]" class="md:w-1/2 px-2 sm:px-4 ml-auto">
          <div v-for="(text, i) in content" :key="i" class="my-4">
            <rich-text :rt="text"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeadlineAndImageColumn',
    props: {
      row: Object
    },
    computed: {
      img () {
        return this.row.primary?.image
      },
      html () {
        return this.row.primary?.headline
      },
      description () {
        return this.row.primary?.description
      },
      reverse () {
        return this.row.slice_label === 'text_right'
      },
      content () {
        if (!this.row.items?.length) return
        return this.row.items.map((t) => {
          return t.text
        })
      }
    }
  }
</script>
