import { createStore } from 'vuex'
import getters from '@/store/getters'
import actions from '@/store/actions'
import mutations from '@/store/mutations'

export default createStore({
  state: {
    settings: null,

    // page seo
    pageTitle: null,
    pageDescription: null,
    pageThumb: null,

    homepage: null,
    consultation: null,
    chapters: null,
    showFeedbackForm: false,
    docs: {},
    pages: {},

    session: null,
    activity: '',
    answers: []
  },
  getters,
  actions,
  mutations
})
